import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";

import FullLogo from "src/assets/img/fullLogo.svg?react";
import BackButton from "src/components/mobileNavigation/BackButton";

import style from "./FullPageContainer.module.scss";

interface IFullPageContainerProps {
  hasLogo?: boolean;
  teamName?: string;
  children: ReactNode;
  logoWidth?: number;
  logoSrc?: string;
  marginBottom?: number;
}

const FullPageContainer = ({
  hasLogo = false,
  teamName,
  children,
  logoWidth = 175,
  logoSrc,
  marginBottom = 4,
}: IFullPageContainerProps) => {
  return (
    <Box minHeight="100dvh" paddingY={2} display="flex" flexDirection="column">
      <Box
        className={style.fullPageContainer}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pt="env(safe-area-inset-top)"
        pb="env(safe-area-inset-bottom)"
        pl="env(safe-area-inset-left)"
        pr="env(safe-area-inset-right)"
      >
        <BackButton />
        {hasLogo && (
          <Stack spacing={1} mb={marginBottom} alignItems="center">
            {logoSrc ? (
              <img src={logoSrc} alt={teamName} width="150px" />
            ) : (
              <FullLogo width={logoWidth} />
            )}
            {teamName && <h2>{teamName}</h2>}
          </Stack>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default FullPageContainer;
