// https://www.sohamkamani.com/javascript/localstorage-with-ttl-expiry/
export const setWithExpiryDate = (
  key: string,
  value: string,
  expirationDate: number,
) => {
  const item = { value, expiry: expirationDate };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiryDate = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date().getTime() / 1000;

  if (now > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const getTeamFilters = (teamId: string) => {
  const stored = localStorage.getItem("teamFilters");
  if (!stored) return {};
  const parsed = JSON.parse(stored);
  return parsed[teamId] || {};
};

export const setTeamFilters = (
  teamId: string,
  newFilters: Record<string, any>,
) => {
  const stored = localStorage.getItem("teamFilters");
  let parsed = {};
  if (stored) {
    parsed = JSON.parse(stored);
  }

  parsed[teamId] = {
    ...(parsed[teamId] || {}),
    ...newFilters,
  };

  localStorage.setItem("teamFilters", JSON.stringify(parsed));
};
