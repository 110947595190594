import Box from "@mui/material/Box";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import "./FileInput.scss";

export interface IFileInputProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
  htmlFor?: string;
  height?: string;
  width?: string;
  pdfOnly?: boolean;
}

const FileInput = forwardRef(
  (
    {
      label,
      htmlFor,
      height,
      width = "100%",
      pdfOnly,
      ...props
    }: IFileInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const accept = pdfOnly ? ".pdf" : "";

    return (
      <Box sx={{ width, height }}>
        <label htmlFor={htmlFor} className="FileInput">
          {label}
          <input {...props} ref={ref} type="file" multiple accept={accept} />
        </label>
      </Box>
    );
  },
);

export default FileInput;
