import { combineReducers } from "@reduxjs/toolkit";

import { reducer, reducerPath } from "src/services/store/apiSlice";
import chatReducer from "src/services/store/chatSlice";
import contactInformationReducer from "src/services/store/contactSlice";
import conversationArchiveDrawerSlice from "src/services/store/conversationArchiveDrawerSlice";
import conversationReducer from "src/services/store/conversationSlice";
import newContactReducer from "src/services/store/newContactSlice";
import pollingReducer from "src/services/store/pollingSlice";
import settingsReducer from "src/services/store/settingsSlice";
import sharedFileSectionReducer from "src/services/store/sharedFileSectionSlice";
import teamReducer from "src/services/store/teamSlice";
import signatureReducer from "src/services/store/signatureRequestSlice";
import queryReducer from "src/services/store/querySlice";
import signatureErrorReducer from "src/services/store/signatureRequestErrorSlice";
import phoneRecordingReducer from "src/services/store/phoneRecordingSlice";
import mobileNavigationReducer from "src/services/store/mobileNavigationSlice";

const rootReducer = combineReducers({
  [reducerPath]: reducer,
  conversations: conversationReducer,
  team: teamReducer,
  contactInformation: contactInformationReducer,
  chat: chatReducer,
  settings: settingsReducer,
  newContact: newContactReducer,
  sharedFileSection: sharedFileSectionReducer,
  conversationArchiveDrawer: conversationArchiveDrawerSlice,
  signatureRequest: signatureReducer,
  phoneRecording: phoneRecordingReducer,
  polling: pollingReducer,
  query: queryReducer,
  signatureRequestError: signatureErrorReducer,
  mobileNavigation: mobileNavigationReducer,
});

export default rootReducer;
