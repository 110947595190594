import { FetchArgs } from "@reduxjs/toolkit/query/react";

import getUniqueObjects from "src/utils/getUniqueObjects";

interface PaginatedQueryDefinition {
  query: (params: any) => string | FetchArgs;
  serializeQueryArgs: (params: any) => string;
  merge: (currentCache: any[], newItems: any[]) => any[];
  forceRefetch: (params: any) => boolean;
}

export default function paginatedQueryDefinition(
  url: string,
): PaginatedQueryDefinition {
  return {
    query: (params) => ({
      url,
      params,
    }),
    serializeQueryArgs: ({ endpointName }) => endpointName,
    merge: (currentCache, newItems) => getUniqueObjects(currentCache, newItems),
    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },
  };
}
