export default function getUniqueObjects(
  firstArray: any[],
  secondArray: any[],
): any[] {
  const mergedMap = new Map<string, any>();

  firstArray?.forEach((item) => {
    mergedMap.set(item.id, item);
  });

  secondArray?.forEach((item) => {
    mergedMap.set(item.id, item);
  });

  return Array.from(mergedMap.values());
}
