import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";
import {
  AssigneeFilter,
  IConversation,
  ISelectedConversation,
} from "src/types";

export interface INotificationState {
  conversationId: string;
  count: number | null;
  last_message: string;
  last_message_type: string;
  last_message_medium: string;
  last_message_sent_by: string;
  isRead: boolean;
}

export interface IConversationsState {
  filteredConversations: Array<IConversation>;
  selectedConversation: ISelectedConversation | null;
  drawerIsOpen: boolean;
  conversationInputValue: { value: string; userIsSearching: boolean };
  assigneeFilters: AssigneeFilter[];
  unarchivedFilter: boolean;
  unresolvedFilter: boolean;
  page: number;
  isFilterApplied: boolean;
  conversationNotification: Array<INotificationState>;
  resetKey: number;
}

export const initialState: IConversationsState = {
  filteredConversations: [],
  selectedConversation: null,
  drawerIsOpen: true,
  conversationInputValue: { value: "", userIsSearching: false },
  assigneeFilters: [],
  unarchivedFilter: false,
  unresolvedFilter: false,
  page: 1,
  isFilterApplied: true,
  conversationNotification: [],
  resetKey: Date.now(),
};

const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },
    setFilteredConversations: (
      state,
      action: PayloadAction<IConversationsState["filteredConversations"]>,
    ) => {
      state.filteredConversations = action.payload;
    },
    setSelectedConversation: (
      state,
      action: PayloadAction<IConversationsState["selectedConversation"] | null>,
    ) => {
      state.selectedConversation = action.payload;
    },
    resetSelectedConversation: (state) => {
      state.selectedConversation = null;
    },
    setConversationInputValue: (state, action: PayloadAction<string>) => {
      state.conversationInputValue.value = action.payload;
      state.isFilterApplied = action.payload === "";
      state.page = 1;
    },
    setConversationUserIsSearching: (state, action: PayloadAction<boolean>) => {
      state.conversationInputValue.userIsSearching = action.payload;
    },
    setIsFilterApplied: (state, action: PayloadAction<boolean>) => {
      state.page = 1;
      state.isFilterApplied = action.payload;
    },
    setAssigneeFilters: (state, action: PayloadAction<AssigneeFilter[]>) => {
      state.page = 1;
      state.assigneeFilters = action.payload;
    },
    setUnarchivedFilter: (state, action: PayloadAction<boolean>) => {
      state.page = 1;
      state.unarchivedFilter = action.payload;
    },
    setUnresolvedFilter: (state, action: PayloadAction<boolean>) => {
      state.page = 1;
      state.unresolvedFilter = action.payload;
    },
    increasePage: (state) => {
      state.page += 1;
    },
    resetConversationsPagination: (state) => {
      state.page = 1;
      state.resetKey = Date.now();
    },
  },
});

export const {
  setFilteredConversations,
  setSelectedConversation,
  setConversationInputValue,
  setConversationUserIsSearching,
  setAssigneeFilters,
  setUnarchivedFilter,
  setUnresolvedFilter,
  increasePage,
  setIsFilterApplied,
  resetSelectedConversation,
  resetConversationsPagination,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
