import { api } from "src/services/store/apiSlice";
import { apiEndpoints } from "src/services/utils";
import TAGS from "src/services/utils/tags";
import { IConversation, IConversationPost } from "src/types";
import paginatedQueryDefinition from "src/services/utils/paginatedQueryDefinition";

export interface GetConversationsParams {
  page?: number;
  assignee_ids?: string;
  search?: string;
  team_id?: string;
  unarchived?: boolean;
  unresolved?: boolean;
  resetKey?: number;
}

const conversationsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query<IConversation[], GetConversationsParams>({
      ...paginatedQueryDefinition(apiEndpoints.conversations()),
      serializeQueryArgs: ({ queryArgs }) => ({
        assignee_ids: queryArgs.assignee_ids,
        search: queryArgs.search,
        unarchived: queryArgs.unarchived,
        unresolved: queryArgs.unresolved,
        team_id: queryArgs.team_id,
        resetKey: queryArgs.resetKey,
      }),
      providesTags: [TAGS.CONVERSATION],
    }),
    getConversationById: builder.query<IConversation, string>({
      query: (id) => apiEndpoints.conversation(id),
      providesTags: (_result, _error, arg) => [
        { type: TAGS.CONVERSATION, id: arg },
      ],
    }),
    getConversationsByContactId: builder.query<IConversation[], string>({
      query: (contactId) =>
        `${apiEndpoints.conversations()}?${new URLSearchParams({
          contact_id: contactId,
        })}`,
    }),
    updateConversation: builder.mutation<
      IConversation,
      Partial<IConversationPost> & Pick<IConversationPost, "id">
    >({
      query: ({ id, ...dataToUpdate }) => ({
        url: apiEndpoints.conversation(id),
        method: "PATCH",
        body: dataToUpdate,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: TAGS.CONVERSATION, id: arg.id },
        TAGS.CONVERSATION,
      ],
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useLazyGetConversationByIdQuery,
  useLazyGetConversationsByContactIdQuery,
  useUpdateConversationMutation,
  useGetConversationByIdQuery,
} = conversationsAPI;
